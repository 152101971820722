import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import PlacementTestChooseTypeOfTestTakerPanel from './PlacementTestChooseTypeOfTestTakerPanel/PlacementTestChooseTypeOfTestTakerPanel';
import {
  setEditedPlacementTest,
  getWizardTestCreditsRequest,
  setStudentsList
} from '../../../redux/reducers/placementTestSessionCreate';
import { clearForm } from '../../../redux/reducers/addStudentsToClassroom';
import { triggerSearch, initialiseInstance } from '../../../redux/reducers/data/search.reducer';

import USER_ROLES from '../../../globals/userRoles';

import style from './AddStudents.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import LicenceCounting from './LicenceCounting/LicenceCounting';
import ManageAddedStudentsPanel from './ManageAddedStudentsPanel/ManageAddedStudentsPanel.js';
import PlacementTestSetNumberOfPlacesForUnknownStudentsPanel from './SetNumberOfPlacesForUnknownStudentsPanel/SetNumberOfPlacesForUnknownStudentsPanel';
import EnrolUser from '../../../routes/MySchool/tabs/StaffTab/panels/EnrolUser/EnrolUser';
import AddExistingStudentsPanel from '../../../routes/ClassroomPage/tabs/StudentsTab/panels/AddStudents/AddStudents';
import SidePanel from '../../SidePanel/SidePanel.js';

function AddStudents({
  placementTestSessionName,
  clearFormAction,
  studentsList = [],
  placementTestSessionIdCreated,
  placementTestSessionId,
  studentsDetails,
  initialiseSearch,
  setEditedPlacementTestAction,
  localizedContent: { placementTests: placementTestsContent },
  getTestCreditsForProductAction,
  setStudentsListAction,
  placementTestProductId,
  currentOrganisationId,
  placesForUnknownStudents = 0,
  triggerSearchAction
}) {
  const { handleStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const [activePanel, setActivePanel] = useState(null);
  const [backActionForNumberOfPlaces, setBackActionForNumberOfPlaces] = useState(() => () => {});

  const openPanel = (panelType, backAction = null) => {
    setActivePanel(panelType);
    if (panelType === 'numberOfPlaces' && backAction) {
      setBackActionForNumberOfPlaces(() => backAction);
    }
  };

  useState(() => {
    getTestCreditsForProductAction({ productId: placementTestProductId, orgId: currentOrganisationId });
  }, []);

  useEffect(() => {
    const sessionId = placementTestSessionId || placementTestSessionIdCreated;
    const filterObject = {
      active: true,
      invited: true,
      archived: true,
      orgId: currentOrganisationId,
      roles: [USER_ROLES.LEARNER],
      classId: sessionId
    };
    const searchSize = 100;
    const searchSort = 'firstName:asc';
    initialiseSearch('placementTestStudents', false, filterObject, searchSize, searchSort);
  }, []);

  useEffect(() => {
    setStudentsListAction(studentsList);
  }, [studentsList]);

  const closePanel = () => {
    setActivePanel(null);
    setBackActionForNumberOfPlaces(null);
    triggerSearchAction('placementTestStudents');
  };

  const closeExistingStudentsPanel = () => {
    closePanel();
    clearFormAction();
  };

  handleStep(async () => {
    setStepOut(true);
    setEditedPlacementTestAction({
      studentsList,
      placesForUnknownStudents
    });
    await animationTimeout();
  });

  const getPanel = () => {
    let Component;
    let componentArgs = {};
    let hideCloseButton = false;
    switch (activePanel) {
      case 'manageAddedStudents':
        Component = ManageAddedStudentsPanel;
        componentArgs = {
          placementTestSessionName,
          studentsDetails,
          failed: false
        };
        break;
      case 'chooseTestTaker':
        Component = PlacementTestChooseTypeOfTestTakerPanel;
        componentArgs = {
          onNext: selectedOption => {
            switch (selectedOption) {
              case 'unknownStudents':
                openPanel('numberOfPlaces', () => openPanel('chooseTestTaker'));
                break;
              case 'newStudents':
                openPanel('newStudents');
                break;
              case 'existingStudents':
                openPanel('existingStudents');
                break;
              default:
                break;
            }
          },
          onClose: closePanel
        };
        break;
      case 'numberOfPlaces':
        Component = PlacementTestSetNumberOfPlacesForUnknownStudentsPanel;
        componentArgs = {
          onBack: backActionForNumberOfPlaces || closePanel,
          isPlacementTest: true,
          placementTestSessionName,
          onClose: closePanel
        };
        break;
      case 'newStudents':
        Component = EnrolUser;
        componentArgs = {
          orgId: currentOrganisationId,
          context: APP_CONSTANTS.ORG_STUDENTS,
          closePanel,
          backAction: () => openPanel('chooseTestTaker'),
          hideAddToClassField: true,
          displayBackButton: true,
          onComplete: closePanel,
          className: style.enrollStudent
        };
        hideCloseButton = true;
        break;
      case 'existingStudents':
        Component = AddExistingStudentsPanel;
        componentArgs = {
          isOpen: true,
          closePanel: closeExistingStudentsPanel,
          backAction: () => openPanel('chooseTestTaker'),
          onComplete: closePanel,
          orgId: currentOrganisationId,
          classId: placementTestSessionId,
          isPlacementTest: true,
          placementTestSessionName
        };
        hideCloseButton = true;
        break;

      default:
        return null;
    }

    return (
      <SidePanel id={activePanel} isOpen={!!activePanel} onClose={closePanel} hideCloseButton={hideCloseButton}>
        <Component {...componentArgs} />
      </SidePanel>
    );
  };

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_ADD_STUDENTS_CONTAINER"
      className={`${fadeOut}`}
    >
      <WizardStep
        titleText={`${placementTestsContent.placement_test_add_students_page_title} ${placementTestSessionName ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
      >
        <div className={style.addStudentsContainer}>
          <LicenceCounting
            setShowManageAddedStudentsPanel={() => openPanel('manageAddedStudents')}
            setShowNumberOfPlacesForUnknownStudentsPanel={() => openPanel('numberOfPlaces', closePanel)}
            setIsChooseTestTakerPanelOpen={() => openPanel('chooseTestTaker')}
          />
          {getPanel()}
        </div>
      </WizardStep>
    </div>
  );
}

AddStudents.propTypes = {
  placementTestSessionName: PropTypes.string,
  studentsList: PropTypes.array,
  initialiseSearch: PropTypes.func,
  placementTestSessionIdCreated: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  studentsDetails: PropTypes.object,
  placementTestProductId: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  getTestCreditsForProductAction: PropTypes.func,
  setEditedPlacementTestAction: PropTypes.func,
  setStudentsListAction: PropTypes.func,
  placesForUnknownStudents: PropTypes.object,
  clearFormAction: PropTypes.func,
  triggerSearchAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, identity, search, placementOnboardingWizard }) => ({
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      currentOrganisationId: identity.currentOrganisationId,
      studentsList: search?.placementTestStudents?.ids,
      studentsDetails: search?.placementTestStudents?.users,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestProductId: placementTestSessionCreate.editedPlacementTest.productId,
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      getTestCreditsForProductAction: getWizardTestCreditsRequest,
      setStudentsListAction: setStudentsList,
      clearFormAction: clearForm,
      initialiseSearch: initialiseInstance,
      triggerSearchAction: triggerSearch
    }
  )
)(AddStudents);
