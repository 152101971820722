import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { orgRoles } from '@oup/shared-node-browser/org';
import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails from './SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails/SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails';
import SelfRegisteredUserOnboardingOrgSetupWizardFooter from './SelfRegisteredUserOnboardingOrganizationSetupWizardFooter';
import SelfRegisteredUserOnboardingWizardAddStaff from './SelfRegisteredUserOnboardingWizardAddStaff/SelfRegisteredUserOnboardingWizardAddStaff';
import SelfRegisteredUserOnboardingWizardSuccess from './SelfRegisteredUserOnboardingWizardSuccess/SelfRegisteredUserOnboardingWizardSuccess';
import SelfRegisteredUserCreateFirstPlacementTestOrClassModal from './SelfRegisteredUserCreateFirstPlacementTestOrClassModal/SelfRegisteredUserCreateFirstPlacementTestOrClassModal';
import {
  closeSelfRegisteredWizard,
  openOnboardingWizard,
  openPlacementTestOnboardingWizard,
  showSelfSelectRoleModal
} from '../../redux/actions/hubUi';
import SelfRegisteredUserOnboardingOrganizationSetupWizardHeader from './SelfRegisteredUserOnboardingOrganizationSetupWizardHeader';
import SelfRegisteredUserOnboardingWizardStopSettingUpOrgModal from './SelfRegisteredUserOnboardingWizardStopSettingUpOrgModal/SelfRegisteredUserOnboardingWizardStopSettingUpOrgModal';

const { SECONDARY_SCHOOL } = orgRoles;

function SelfRegisteredUserOnboardingOrganizationSetupWizard({
  secondWizardType,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction,
  openClassOnboardingWizardAction,
  openPlacementTestOnboardingWizardAction
}) {
  const [isOpenStopSettingUpOrgModal, setIsOpenStopSettingUpOrgModal] = useState(false);
  const [isOpenCreateFirstPlacementTestOrClassModal, setIsOpenCreateFirstPlacementTestOrClassModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '',
    primaryEmail: '',
    webAddress: '',
    role: SECONDARY_SCHOOL
  });

  const isPlacementCentre = secondWizardType === ORG_REGISTRATION_CONTEXT.PLACEMENT;

  return (
    <>
      <Wizard
        header={
          <SelfRegisteredUserOnboardingOrganizationSetupWizardHeader
            secondWizardType={secondWizardType}
            setIsOpenStopSettingUpOrgModal={setIsOpenStopSettingUpOrgModal}
            setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
          />
        }
        footer={
          <SelfRegisteredUserOnboardingOrgSetupWizardFooter
            branch={secondWizardType}
            formData={formData}
            setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
            showSelfSelectRoleModalAction={showSelfSelectRoleModalAction}
          />
        }
      >
        <SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails
          branch={secondWizardType}
          formData={formData}
          setFormData={setFormData}
        />
        <SelfRegisteredUserOnboardingWizardAddStaff branch={secondWizardType} />
        <SelfRegisteredUserOnboardingWizardSuccess />
      </Wizard>
      <SelfRegisteredUserOnboardingWizardStopSettingUpOrgModal
        isOpen={isOpenStopSettingUpOrgModal}
        closeWarningModal={() => setIsOpenStopSettingUpOrgModal(false)}
      />
      <SelfRegisteredUserCreateFirstPlacementTestOrClassModal
        isOpen={isOpenCreateFirstPlacementTestOrClassModal}
        onClose={() => {
          setIsOpenCreateFirstPlacementTestOrClassModal(false);
          showSelfSelectRoleModalAction(false);
        }}
        onNext={() => {
          setIsOpenCreateFirstPlacementTestOrClassModal(false);
          showSelfSelectRoleModalAction(false);
          closeSelfRegisteredWizardAction();
          if (isPlacementCentre) openPlacementTestOnboardingWizardAction();
          else openClassOnboardingWizardAction();
        }}
        wizardType={secondWizardType}
      />
    </>
  );
}

SelfRegisteredUserOnboardingOrganizationSetupWizard.propTypes = {
  secondWizardType: PropTypes.string,
  showSelfSelectRoleModalAction: PropTypes.func,
  closeSelfRegisteredWizardAction: PropTypes.func.isRequired,
  openClassOnboardingWizardAction: PropTypes.func,
  openPlacementTestOnboardingWizardAction: PropTypes.func
};

export default compose(
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal,
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard,
    openClassOnboardingWizardAction: openOnboardingWizard,
    openPlacementTestOnboardingWizardAction: openPlacementTestOnboardingWizard
  })
)(SelfRegisteredUserOnboardingOrganizationSetupWizard);
