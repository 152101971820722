import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import copy from 'copy-to-clipboard';
import Toggle from '@oup/shared-front-end/src/components/Toggle';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';

import { ICON_COPY, ICON_INFORMATION_CIRCLE } from '@oup/shared-front-end/src/svg/oup';
import PanelHeading from '../../../../../components/PanelHeading/PanelHeading';
import PopoutActionFooter from '../../../../../components/PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer';

import {
  closeEditJoiningCodePanel,
  submitEditJoiningCodeRequest,
  refreshPlacementTestList,
  submitEditPlacesInTestSession
} from '../../../../../redux/actions/placementTests';

import PlacesRemainingInSession from './components/PlacesRemainingInSession';
import withLocalizedContent from '../../../../../language/withLocalizedContent';

import styles from './ManageJoiningCode.scss';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';

import { HubIllustrationAltText, HubIllustrationConstants } from '../../../../../globals/hubConstants';

function ManageJoiningCode({
  customOrgId,
  closeEditJoiningCodePanelAction,
  submitEditJoiningCodeAction,
  loading,
  localizedContent: { manageJoiningCode: content, hubGlossary: hubContent, placementTests: placementTestsContent },
  codeDetails,
  joiningCodeUpdated,
  refreshPlacementTestListAction,
  submitEditPlacesInTestSessionAction,
  orgId,
  groupId,
  availableCredits,
  placementTestSessionNameValue,
  placesInTestSessionUpdated,
  errorResponse,
  productId,
  placesRemainingInSession = 0
}) {
  const [classCodeWidth, setClassCodeWidth] = useState(0);
  const { enabled, codeId, code: joiningCode } = codeDetails;
  const [isCodeTouched, setIsCodeTouched] = useState(false);
  const [code, setCode] = useState('');

  const [places, setPlaces] = useState(placesRemainingInSession);
  const [error, setError] = useState(false);
  const [differenceFromMax, setDifferenceFromMax] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false);
  const [codeEnabled, setCodeEnabled] = useState(false);
  const [codeCopied, setCodeCopied] = useState(hubContent.placement_test_session_copy_joining_code);

  const setErrorFunction = param => setError(param);

  const setDifferenceFromMaxFunction = () =>
    setDifferenceFromMax(places - (availableCredits + placesRemainingInSession));

  const decrementPlacesInSession = () => {
    setPlaces(prev => (prev === 0 ? 0 : prev - 1));
  };
  const incrementPlacesInSession = () => {
    setPlaces(prev => prev + 1);
  };

  const onCopyFunction = text => {
    setCodeCopied(hubContent.placement_test_session_joining_code_copied);
    copy(text);
    setTimeout(() => {
      setCodeCopied(hubContent.placement_test_session_copy_joining_code);
    }, 500);
  };

  const onToggle = () => {
    setCodeEnabled(!codeEnabled);
  };

  const handleCodeChange = () => {
    refreshPlacementTestListAction();
    closeEditJoiningCodePanelAction();
  };

  const ToggleComponent = useCallback(
    () => <Toggle onChange={onToggle} toggled={codeEnabled} name="joiningCodeToggle" />,
    [codeEnabled]
  );

  useEffect(() => {
    setIsCodeTouched(code !== joiningCode);
  }, [code]);

  useEffect(() => {
    setIsEnabled(enabled);
    setCode(joiningCode);
    setCodeEnabled(enabled);
  }, [Object.keys(codeDetails).length]);

  useEffect(() => {
    const button = document.getElementById('panelNavLink');
    return () => {
      button.style.display = !loading ? 'none' : 'inline-flex';
    };
  }, [loading]);

  const setClassCodeWidthRef = element => {
    if (element) {
      const width = element.getBoundingClientRect().width;
      setClassCodeWidth(width);
    }
  };

  if (loading) {
    return (
      <LoadingSpinner text={false}>
        <div className={styles.loadingText}>
          <span className={styles.pleaseWait}>{hubContent.please_wait}</span>
          {joiningCode && (
            <span className={styles.updatingSession}>{placementTestsContent.placement_test_loading_text}</span>
          )}
        </div>
      </LoadingSpinner>
    );
  }

  if (!errorResponse && (placesInTestSessionUpdated || joiningCodeUpdated)) {
    return (
      <div className={styles.updateSuccess}>
        <div className={styles.imageContainerSuccess}>
          <img src={HubIllustrationConstants.SUCCESS} alt={HubIllustrationAltText.SUCCESS} />
          <h2>{placementTestsContent.placement_test_changes_have_been_saved}</h2>
        </div>
        <div className={styles.doneButtonContainer}>
          <PopoutActionFooter primaryButtonText={hubContent.done_button} primaryButtonAction={handleCodeChange} />
        </div>
      </div>
    );
  }

  const submitJoiningCodeQuery = {
    ...(code !== joiningCode ? { code } : {}),
    ...(isEnabled !== codeEnabled ? { enabled: codeEnabled } : {})
  };

  const submitPlacesInSessionQuery = {
    ...(codeEnabled && placesRemainingInSession !== places ? { placesForUnknownStudents: places } : {}),
    productId
  };

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={content.manageJoiningCodeTitle}
          subtitle={`${hubContent.for_label} <strong>${placementTestSessionNameValue}</strong>`}
        />
      }
      footerContent={
        <Footer
          wizardButtons
          withIcon={false}
          primaryButtonLabel={hubContent.save}
          primaryButtonAction={() => {
            if (Object.keys(submitJoiningCodeQuery).length) {
              submitEditJoiningCodeAction({ orgId, joiningCodeId: codeId, data: submitJoiningCodeQuery });
            }
            if (Object.keys(submitPlacesInSessionQuery).length > 1) {
              submitEditPlacesInTestSessionAction({
                orgId,
                groupId,
                data: submitPlacesInSessionQuery
              });
            }
          }}
          secondaryButtonLabel={hubContent.cancel}
          secondaryButtonAction={closeEditJoiningCodePanelAction}
          primaryButtonAttributes={{
            disabled: error
          }}
        />
      }
      containerClassName={styles.customiseToggleContainer}
    >
      <div className={styles.manageJoiningCode}>
        <div className={styles.manageJoiningCodeContent}>
          <div className={styles.toggleButtonAndText}>
            <ToggleComponent />
            <span className={styles.allowStudents}>{content.allowStudents}</span>
          </div>
          <div className={styles.textInputContent}>
            <span className={styles.joiningCode}>{hubContent.joining_code_label}</span>
            <div className={styles.textInputClassCodeContainer}>
              <span ref={setClassCodeWidthRef} className={styles.placeHolderClassCode}>
                {customOrgId}-
              </span>
              {isCodeTouched && (
                <span className={styles.infoIcon}>
                  <ICON_INFORMATION_CIRCLE />
                </span>
              )}
              <input
                style={{
                  paddingLeft: `calc(${classCodeWidth}px + 1.4%)`
                }}
                disabled={!codeEnabled}
                className={styles.codeEditInput}
                type="text"
                value={code}
                onChange={e => {
                  setCode(e.target.value);
                }}
              />
              <Button
                variant="outline"
                dataAttributes={{ tooltip: codeCopied }}
                text="Copy"
                icon={{ component: <ICON_COPY /> }}
                disabled={!codeEnabled}
                onClick={e => {
                  onCopyFunction(`${customOrgId}-${code}`);
                  e.currentTarget.blur();
                }}
                className={styles.copyCodeButton}
              />
            </div>
            {isCodeTouched && <span className={styles.ifCodeIsChanged}> {content.dontChangeCode}</span>}
          </div>
          <PlacesRemainingInSession
            enabled={codeEnabled}
            places={places}
            error={error}
            decrementPlacesInSession={decrementPlacesInSession}
            incrementPlacesInSession={incrementPlacesInSession}
            setError={setErrorFunction}
            joiningCode={joiningCode}
            availableCredits={availableCredits}
            placesForUnknownStudents={placesRemainingInSession}
            differenceFromMax={differenceFromMax}
            setDifferenceFromMax={setDifferenceFromMaxFunction}
          />
          <div className={styles.copyToStudents}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: placementTestsContent.placement_test_copy_and_share
                  .replace('[englishhubUrl]', `<b>${hubContent.english_hub_url}</b>`)
                  .replace('[enterCode]', `<b>${hubContent.enter_a_code}</b>`)
              }}
            />
          </div>

          <div className={styles.learnMoreAboutCodes}>
            <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
              {placementTestsContent.placement_test_add_students_page_learn_about_joining_codes}
            </TextLink>
          </div>
        </div>
      </div>
    </ScrollContainer>
  );
}
ManageJoiningCode.propTypes = {
  customOrgId: PropTypes.string,
  localizedContent: PropTypes.object,
  closeEditJoiningCodePanelAction: PropTypes.func,
  submitEditJoiningCodeAction: PropTypes.func,
  loading: PropTypes.bool,
  codeDetails: PropTypes.object,
  joiningCodeUpdated: PropTypes.bool,
  refreshPlacementTestListAction: PropTypes.func,
  groupId: PropTypes.string,
  orgId: PropTypes.string,
  submitEditPlacesInTestSessionAction: PropTypes.func,
  availableCredits: PropTypes.number,
  placementTestSessionNameValue: PropTypes.string,
  placesInTestSessionUpdated: PropTypes.bool,
  errorResponse: PropTypes.string,
  productId: PropTypes.string,
  placesRemainingInSession: PropTypes.number
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests', 'manageJoiningCode'),
  connect(
    ({
      placementTestSessionCreate,
      organisations,
      identity,
      loadPlacementTestsReducer: {
        loading,
        codeDetails,
        joiningCodeUpdated,
        availableCredits,
        errorResponse,
        placesInTestSessionUpdated
      }
    }) => ({
      customOrgId: organisations?.data[identity.currentOrganisationId]?.customId,
      loading,
      codeDetails,
      joiningCodeUpdated,
      orgId: identity.currentOrganisationId,
      groupId: placementTestSessionCreate.placementTestSessionId,
      availableCredits,
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      errorResponse,
      placesInTestSessionUpdated,
      productId: placementTestSessionCreate.placementTest.productId
    }),
    {
      closeEditJoiningCodePanelAction: closeEditJoiningCodePanel,
      submitEditJoiningCodeAction: submitEditJoiningCodeRequest,
      refreshPlacementTestListAction: refreshPlacementTestList,
      submitEditPlacesInTestSessionAction: submitEditPlacesInTestSession
    }
  )
)(ManageJoiningCode);
